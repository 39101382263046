







































import Vue from "vue";
// import { defineComponent } from "vue";
import store from "@/store";

import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Spinners from "@/components/utilities/Spinners.vue";

import { QuestionOfTheDay } from "@/types/interfaces";

export default Vue.extend({
    components: {
        PageTitleHeader,
        Spinners
    },
    data() {
        return {
            questionsLoaded: false
        };
    },
    computed: {
        optionsStore(): any {
            return this.$store.state.optionsVuexModule;
        },
        options(): any {
            return this.optionsStore.options;
        },
        questions(): any {
            return this.optionsStore.options.questionsOfTheDay
                ? this.optionsStore.options.questionsOfTheDay
                : [];
        },
        modifiedQuestions(): Array<QuestionOfTheDay> {
            const today = new Date().toISOString();

            return [...this.questions]
                .filter((question: QuestionOfTheDay) => {
                    if (
                        Vue.prototype.MgFormatISODateTime(
                            question.date,
                            "yyyy-mm-dd"
                        ) <=
                        Vue.prototype.MgFormatISODateTime(today, "yyyy-mm-dd")
                    ) {
                        return question;
                    }
                })
                .sort((a: QuestionOfTheDay, b: QuestionOfTheDay) => {
                    return a.id.localeCompare(b.id);
                })
                .reverse();
        },

        pageOptions() {
            return store.getters.getPageOptions("QuestionOfTheDay");
        },

        textHeader(): string {
            return this.pageOptions?.textHeader;
        }
    },
    mounted() {
        this.setupList();
    },
    methods: {
        async setupList(): Promise<void> {
            await this.$store.dispatch("getOptions", ["questionsOfTheDay"]);
            this.questionsLoaded = true;
        },
        routeDetails(id: string) {
            return {
                name: "QuestionDetails",
                params: {
                    qid: id
                }
            };
        }
    }
});
